import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { media } from '../utils/media-queries';
import RichText from '../components/richtext';
import { useClientSide } from '../hooks/useClientSide';
import { useEventListener } from '../hooks/useEventListener';
import BrotfolioImg from '../components/brotfolioImg';

const PortfolioProject = ({ data }) => {
  const { content } = data.portfolioProject;

  const size = useWindowSize();
  const isMobile = size.width < 768;

  const isBothColumns = item => (item.position && !isMobile ? '1 / 3' : '');

  const isClient = useClientSide();

  const ref = useRef();

  const keyScroll = e => {
    if (ref.current === null) return;

    const speed = 10;
    if (e.keyCode === 40 || e.keyCode === 39) ref.current.scrollLeft += speed;
    if (e.keyCode === 38 || e.keyCode === 37) ref.current.scrollLeft -= speed;
  };

  useEventListener('keydown', keyScroll, isClient && document);

  return (
    <Layout color="white">
      <SEO siteTitle="portfolio" />
      <StyledWrapper ref={ref}>
        <div className="grid">
          {content.map((item, i) =>
            item.__typename === 'SanityArtImage' ? (
              <BrotfolioImg
                key={i}
                style={{ gridColumn: isBothColumns(item) }}
                fluid={item.image.asset.fluid}
                alt={item.alt}
                caption={item.caption}
              />
            ) : (
              <div
                key={i}
                className="text"
                style={{ gridColumn: isBothColumns(item) }}
              >
                <RichText blocks={item._rawText} />
              </div>
            )
          )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.section`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-top: var(--spacing-XS);

  .grid {
    .text {
      padding: 0 var(--spacing-XXS) var(--spacing-XXS) var(--spacing-XXS);
    }
  }

  .img-wrapper {
    position: relative;

    .img {
      height: 100%;
      object-fit: cover;
    }

    figcaption {
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      p {
        padding: calc(var(--spacing-XXXS) / 3) var(--spacing-XXXS);
      }
    }
  }

  @media ${media.M} {
    overflow-y: scroll;
    height: 80vh;
    padding-top: 0;
    padding-right: 10vh;

    .grid {
      .text {
        padding: 0 var(--spacing-XXS) var(--spacing-XXS) 0;
      }

      .img {
        max-height: 100%;
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    portfolioProject: sanityPortfolioProject(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      content {
        ... on SanityArtImage {
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          portrait
          position
          caption
        }
        ... on SanityArtText {
          _rawText
          position
        }
      }
    }
  }
`;

PortfolioProject.propTypes = {
  data: PropTypes.object,
};

export default PortfolioProject;
