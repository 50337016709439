/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const BrotfolioImg = ({ style, fluid, alt, caption }) => {
  const [showCaption, setShowCaption] = useState(false);

  return (
    <figure
      style={style}
      className="img-wrapper"
      onMouseOver={() => setShowCaption(true)}
      onMouseLeave={() => setShowCaption(false)}
    >
      <Img className="img" fluid={fluid} alt={alt} />
      {showCaption && caption && (
        <figcaption>
          <p>{caption}</p>
        </figcaption>
      )}
    </figure>
  );
};

BrotfolioImg.propTypes = {
  style: PropTypes.string,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  caption: PropTypes.string,
};

export default BrotfolioImg;
